export const myPhotos = [
  {
    src: "https://website-photos-989832117645.s3.amazonaws.com/DSC02445-HDR-Enhanced.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://website-photos-989832117645.s3.amazonaws.com/DSC02098.jpg",
    width: 2,
    height: 3
  },
  {
    src: "https://website-photos-989832117645.s3.amazonaws.com/DSC02078.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://website-photos-989832117645.s3.amazonaws.com/DSC01230.jpg",
    width: 1,
    height: 1
  },
  {
    src: "https://website-photos-989832117645.s3.amazonaws.com/DSC01182.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://website-photos-989832117645.s3.amazonaws.com/DSC00916.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://website-photos-989832117645.s3.amazonaws.com/DSC00758.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://website-photos-989832117645.s3.amazonaws.com/DSC00716.jpg",
    width: 1,
    height: 1
  },
  {
    src: "https://website-photos-989832117645.s3.amazonaws.com/DSC00278.jpg",
    width: 1,
    height: 1
  },
  {
    src: "https://website-photos-989832117645.s3.amazonaws.com/DSC00159.jpg",
    width: 1,
    height: 1
  }
];
